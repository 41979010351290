import React from 'react';
import { useAuth } from './AuthProvider';

const UserContext = React.createContext();

// the UserProvider in user-context.js is basically:
const UserProvider = props => (
  <UserContext.Provider value={useAuth().data.user} {...props} />
);

// and the useUser hook is basically this:
const useUser = () => React.useContext(UserContext);

export { UserProvider, useUser };
