import React, { useState, useEffect } from "react";
// import { Redirect } from "react-router-dom";
import log from "loglevel";

const AuthContext = React.createContext();

function AuthProvider(props) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [existingToken, setExistingToken] = useState(localStorage.getItem(process.env.REACT_APP_ADMIN_AUTH_TOKEN_NAME));

  useEffect(() => {
    async function checkToken(token) {
      log.info("AuthProvider:", "checkToken");
      try {
        const rawResponse = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/admin/check_token`, {
          method: "GET",
          headers: {
            "Authorization": `Bearer ${token}`
          }
        });
        if (rawResponse.status === 200) {
          const json = await rawResponse.json();
          log.debug("AuthProvider:", "checkToken", "token valid");
          log.debug("AuthProvider:", "checkToken", json);
          setData(d => ({
            ...d,
            user: {
              ...json.user,
              token: token
            }
          }));
        } else {
          log.debug("AuthProvider:", "checkToken", "token invalid");
          localStorage.removeItem(process.env.REACT_APP_ADMIN_AUTH_TOKEN_NAME);
          setExistingToken(null);
          setData({});
          // return <Redirect to="/" />;
        }
        // return rawResponse.status;
      } catch (err) {
        log.error("AuthProvider:", "checkToken", err);
        setExistingToken(null);
        setData({});
        // localStorage.removeItem(process.env.REACT_APP_ADMIN_AUTH_TOKEN_NAME);
        // setExistingToken(null);
        // return <Redirect to="/" />;
        // TODO redirect back to log in?
      } finally {
        setLoading(false);
      }
    }

    if (existingToken) {
      checkToken(existingToken);
    } else {
      setLoading(false);
    }

    // return () => {
    //   log.debug("AuthProvider:", "Unmount");
    // }

  }, [existingToken]);

  useEffect(() => {
    log.debug("AuthProvider:", "data changed", data);
  }, [data]);

  // 🚨 this is the important bit.
  // Normally your provider components render the context provider with a value.
  // But we post-pone rendering any of the children until after we've determined
  // whether or not we have a user token and if we do, then we render a spinner
  // while we go retrieve that user's information.

  if (loading) {
    return (
      <div className="w-full h-full">Loading...</div>
    );
  }

  const login = async (e) => {
    e.preventDefault();
    log.info("AuthProvider:", "login");
    let data = {};
    if (e.target.elements["email"]) {
      data.email = e.target.elements["email"].value;
      data.password = e.target.elements["staff-password"].value;
    } else {
      data.key = e.target.elements["room-key"].value;
      data.password = e.target.elements["performer-password"].value;
    }
    try {
      log.info("requesting", `${process.env.REACT_APP_API_ENDPOINT}/api/admin/login`);
      const rawResponse = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/admin/login`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      });
      if (rawResponse.status === 200) {
        const json = await rawResponse.json();
        if (json && json.token) {
          log.debug("AuthProvider:", "login successful", json);
          setData({
            ...data,
            user: {
              ...json.user,
              token: json.token
            }
          });
          localStorage.setItem(process.env.REACT_APP_ADMIN_AUTH_TOKEN_NAME, json.token);
          setErrorMessage("");
        } else {
          log.warn("AuthProvider:", "login did not receive token");
          setErrorMessage("Something went wrong");
        }
      } else if (rawResponse.status === 401 || rawResponse.status === 403) {
        log.warn("AuthProvider:", "incorrect password");
        setErrorMessage("Incorrect password");
      }
      return rawResponse.status;
    } catch (err) {
      log.error("AuthProvider:", "login", err);
      setErrorMessage("Server error");
    }
  };

  const request = async (url, options) => {
    try {
      const rawResponse = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/${url}`, {
        ...options,
        headers: {
          "Authorization": `Bearer ${data.user.token}`
        }
      });
      return rawResponse;
    } catch (err) {
      log.error("AuthProvider:", "request", err);
    }
  };

  const logout = () => {
    log.info("AuthProvider:", "logout");
    localStorage.removeItem(process.env.REACT_APP_ADMIN_AUTH_TOKEN_NAME);
    setData({});
  }; // clear the token in localStorage and the user data

  // note, I'm not bothering to optimize this `value` with React.useMemo here
  // because this is the top-most component rendered in our app and it will very
  // rarely re-render/cause a performance problem.
  return (
    <AuthContext.Provider value={{data, errorMessage, login, logout, request}} {...props} />
  );
}

const useAuth = () => React.useContext(AuthContext);

export { AuthProvider, useAuth };
