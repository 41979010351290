import React, { Suspense } from 'react';
import { useUser } from './context/UserProvider';

const AuthenticatedApp = React.lazy(() => import('./AuthenticatedApp'));
const UnauthenticatedApp = React.lazy(() => import('./UnauthenticatedApp'));

// import AuthenticatedApp from './AuthenticatedApp.jsx';
// import UnauthenticatedApp from './UnauthenticatedApp.jsx';

function App() {
  const user = useUser();

  if (user) {
    return (
      <Suspense fallback={<Loading />}>
        <AuthenticatedApp />
      </Suspense>
    );
  } else {
    return (
      <Suspense fallback={<Loading />}>
        <UnauthenticatedApp />
      </Suspense>
    );
  }
}

export default App;

function Loading() {
  return (
    <div className="w-full h-full">Loading...</div>
  );
}