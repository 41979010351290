import React from "react";
import { HelmetProvider } from "react-helmet-async";
import { AuthProvider } from "./AuthProvider";
import { UserProvider } from "./UserProvider";

function AppProviders({children}) {
  return (
    <HelmetProvider>
      <AuthProvider>
        <UserProvider>
          {children}
        </UserProvider>
      </AuthProvider>
    </HelmetProvider>
  );
}

export default AppProviders;
